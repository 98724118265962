import React, { useEffect, useState } from "react";
import UserService from "../services/user.service";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Box } from '@mui/system';
import Hidden from '@mui/material/Hidden'
import UserEditModal from "./_UserEditModal";
import CommonDocuments from "./_commonDocuments";
import UserDocuments from "./_userDocuments";
import CustomerManager from "./_customerManager";
import Reklamationen from "./_reklamationen";

const Dashboard = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [activeState, setActiveState] = useState(0);
  const handleActiveState = (e) => { setActiveState(e) }

  useEffect(() => {
    async function fetchCurrentUser() {
      try {
        const user = await UserService.getCurrentUser();
        setCurrentUser(user);
      } catch (error) {
        console.error("Error fetching current user:", error);
      }
    }

    fetchCurrentUser();
  }, []);

  const steps = [
    "Kaufentscheidung",
    "Finanzierung",
    "Notartermin",
    "Bauphase",
    "Bemusterung ",
    "Vorabnahme",
    "Abnahme",
    "Gewährleistung"
  ];

  if (!currentUser) {
    return null; // You can return a loading indicator or any other fallback UI while waiting for user data
  }

  return (
    <Box sx={{ background: "#F0F2F5", py: 5 }}>
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Hidden mdDown>
            <Grid item mb={0} xs={0} md={12}>
              <Box py={6} textAlign={"center"} sx={{ background: "#1b1e21" }}>
                <Stepper activeStep={activeState} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
            </Grid>
          </Hidden>
          <Grid item xs={12} lg={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={12}>
                <Paper elevation={1} sx={{ height: "100%" }}>
                  <Box className="dashboard-user-details" px={3} py={3}>
                    <UserEditModal />
                    <h2>Ihre Kontaktdaten</h2><br></br>
                    <table>
                      <tbody>
                        <tr>
                          <td>Name:</td>
                          <td>{currentUser.fullname}</td>
                        </tr>
                        <tr>
                          <td>Straße:</td>
                          <td>{currentUser.adresse}</td>
                        </tr>
                        <tr>
                          <td>Ort:</td>
                          <td>{currentUser.zip} {currentUser.ort}</td>
                        </tr>
                        <tr>
                          <td style={{ paddingRight: '15px' }}>E-Mail:</td>
                          <td>{currentUser.email}</td>
                        </tr>

                        {currentUser.mobile ?
                          <tr>
                            <><td>Mobil:</td><td>{currentUser.mobile}</td></>
                          </tr>
                          : null}

                        {currentUser.phone ?
                          <tr>
                            <><td>Tel:</td><td>{currentUser.phone}</td></>
                          </tr>
                          : null}
                      </tbody>
                    </table>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6} lg={12}>
                <Paper elevation={1} >
                  <CustomerManager />
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Reklamationen />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper elevation={1} mb={3}>
                  <Box pt={3} pb={3} px={3} sx={{ textAlign: 'left', '@media screen and (min-width: 1200px)': { textAlign: 'center' } }}>
                    <h2>Ihre Unterlagen</h2>
                    <UserDocuments handleActiveState={handleActiveState} />
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper elevation={1}>
                  <Box pt={3} pb={3} px={3} sx={{ textAlign: 'left', '@media screen and (min-width: 1200px)': { textAlign: 'center' } }}>
                    <h2>Allgemeine Dokumente</h2>
                    <CommonDocuments />
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Dashboard;