import React from "react";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Box } from '@mui/system';
import { useState, useEffect } from 'react';
import { Button } from '@mui/base/Button';
import { useNavigate } from "react-router-dom";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CircularProgress from '@mui/material/CircularProgress';

import SharePointService from '../services/sharepoint.service';
import { Typography } from "@mui/material";

export default function Reklamationen() {

    const [reklamationen, setReklamationen] = useState([]);
    const getReklamationen = (e) => { setReklamationen(e) }

    const SPgetReklamationen = async() => {
        let result = await SharePointService.getReklamationen()
        getReklamationen(result)
    } 

    useEffect(() => {
        try {
            SPgetReklamationen()
        } catch (e) {
            console.error(e);
        }
    }, []);

    var reklamationenHTML = '';
if (reklamationen.length >= 0) {
    reklamationenHTML = reklamationen.map((value, index) => (
        <TableRow key={index}>
            <TableCell sx={{ '&:hover': { textDecoration: "none" } }}>{value.Raum}</TableCell>
            <TableCell sx={{ '&:hover': { textDecoration: "none" } }}>{value.Created}</TableCell>
            <TableCell sx={{ '&:hover': { textDecoration: "none" } }} align="right">{value.Status}</TableCell>
        </TableRow>
    ));
}

    return(
        <Box>
            <Paper elevation={1} >
            <Box pt={3} px={3} textAlign={"left"}>
            <h2>Reklamationen</h2><br></br>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" sx={{ fontWeight: "bold", '&:hover': {textDecoration: "none"} }} >Raum</TableCell>
                            <TableCell align="left" sx={{ fontWeight: "bold", '&:hover': {textDecoration: "none"} }} >Datum</TableCell>
                            <TableCell align="right" sx={{ fontWeight: "bold", '&:hover': {textDecoration: "none"} }} >Status</TableCell>
                        </TableRow>
                    </TableHead> 
                    {reklamationenHTML}
                </Table>
            </Box>
            <Box py={3} px={3} textAlign={'center'}>
            </Box>                
            </Paper>
        </Box>
    )
}