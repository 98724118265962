import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-validation/build/form";
import { FormControl} from '@mui/base/FormControl';
import { Input} from '@mui/base/Input';
import { Button } from '@mui/base/Button';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import logo from './../assets/weinberger_logo_black.svg'
import LoginBG from './../assets/pfedelbachpost5-korrektur2-Kopie.jpg'


const Changelog = () => {
  return(
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid item xs={12} sm={12} md={7} sx={{
          backgroundImage: `url(${LoginBG})`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
        <Grid item xs={12} sm={12} md={5} component={Paper} elevation={6} px={6}>
            <Box sx={{ textAlign: 'center', mb: 5}}>
              <img style={{marginBottom: '20px'}} src={logo} />
              <h2>Änderungen</h2>
            </Box>
            <Box sx={{ pt: 5, display: 'flex', flexDirection: 'column', maxHeight: "80vh", overflowY: "scroll" }}>
                <Box sx={{ textAlign: 'left', mb: 5}}>
                    <Typography variant="h5">Roadmap</Typography>
                    <ul> 
                      <li>keine anstehenden Änderungen</li>
                    </ul>
                </Box>
                <Box sx={{ textAlign: 'left', mb: 5}}>
                    <Typography variant="h5">v1.1.7 - 07.05.2024</Typography>
                    <ul>
                        <li>Es wird nur eine Reklamation angezeigt</li>
                        <li>Email "Fullname" korrigiert</li>
                        <li>Raum in die Reklamationsmail eingefügt</li> 
                    </ul>
                </Box>
                <Box sx={{ textAlign: 'left', mb: 5}}>
                    <Typography variant="h5">v1.1.6 - 03.05.2024</Typography>
                    <ul>
                        <li>1x Pro Tag neue Dokumente Workflow</li>
                        <li>Tel und Mobil sind beim Daten ändern, sowie beim User anlegen optionale Felder</li>
                        <li>Im Dashboard werden Tel und Mobil nur angezeigt, wenn etwas drin steht</li>
                        <li>Vorname & Name überall gegen "Name" ausgetauscht <br></br>--{">"} initialer Wert von "Name" = "Vorname[Leerzeichen]Nachname"</li>
                        <li>Wenn Email mit SharePoint nicht übereinstimmt, werden keine Dokumente angezeigt, statt einer Fehlermeldung</li>
                        
                    </ul>
                </Box>
                <Box sx={{ textAlign: 'left', mb: 5}}>
                    <Typography variant="h5">v1.1.5 - 24.04.2024</Typography>
                    <p>Reklamation</p>
                    <ul>
                      <li>Validierung Termineingabe</li>
                    </ul>
                </Box>
                <Box sx={{ textAlign: 'left', mb: 5}}>
                    <Typography variant="h5">v1.1.4 - 24.04.2024</Typography>
                    <p>Technik</p>
                    <ul>
                        <li>Management Oberfläche, wo ihr Kunden bearbeiten und den letzten Login nachschauen könnt<br></br>
                          Wenn sich ein Admin einloggt, kann er /admin aufrufen und erhält die Übersicht. Sämtliche anderen User sind dort ausgesperrt.
                        </li>
                        
                    </ul>
                </Box>
                <Box sx={{ textAlign: 'left', mb: 6}}>
                    <Typography variant="h5" mb={2}>v1.1.3 - 22.04.2024</Typography>
                    <p>Technik</p>
                    <ul>
                        <li>SharePoint verhindert jetzt doppelte Secrets mit einer Fehlermeldung</li>
                        <li>Test-Umgebung aufgebaut</li>
                        <li>Email-Server eingebunden</li>
                    </ul>

                    <p>Registrierung</p>
                    <ul>
                        <li>Feld Anrede raus</li>
                        <li>Erfolgsmeldung + das Formular wird nach 3 Sekunden geleert</li>
                        <li>„Login“ Button auf Signup-Seite umbenannt</li>
                    </ul>

                    <p>Passwort vergessen</p>
                    <ul>
                        <li>Erfolgs- und Fehlermeldung auf "Passwort vergessen"-Seite</li>
                        <li>E-Mails werden jetzt zugestellt</li>
                    </ul>
                    
                    <p>Reklamation</p>
                    <ul>
                        <li>einige Tippfehler Reklamation entfernt</li>                
                        <li>Mail geht an Benutzer  + reklamationen@weinberger-immobilien.de</li>
                    </ul>

                    <p>Änderung der eigenen Daten</p>
                    <ul>
                        <li>Popup schließt jetzt automatisch nach 4 Sekunden</li>
                        <li>E-Mail geht auch an Kundenbetreuer</li>
                    </ul>
                </Box>
            </Box>
        </Grid>
      </Grid>
  );
};

export default Changelog;