import * as React from 'react';
import { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import { Box } from '@mui/system';
import { Typography } from "@mui/material";

import UserService from "../services/user.service";
import AdminEditModal from "./_AdminEditModal";


export default function Admin(){
  const [users, setUsers] = useState([]);
  const handleUsers = (e) => { setUsers(e);};

  const GetUsers = async() => {
    handleUsers( await UserService.getAllUsers() )
  }

  useEffect(() => {
    try {
      GetUsers();
    } catch (e) {
        console.error(e);
    }
}, []);

  return (
    <Box sx={{background: "#F0F2F5", py: 5 }}>
      <Container maxWidth="xl">
        <Typography variant='h4' mb={5}>Admin Dashboard</Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{fontWeight: "bold", '&:hover': {textDecoration: "none"}}}>Kunde</TableCell>
                <TableCell sx={{fontWeight: "bold", '&:hover': {textDecoration: "none"}}}>E-Mail</TableCell>
                <TableCell sx={{fontWeight: "bold", '&:hover': {textDecoration: "none"}}}>SharePointSecret</TableCell>
                <TableCell align="right" sx={{fontWeight: "bold", '&:hover': {textDecoration: "none"}}}>Letzter Login</TableCell>
                <TableCell align="right"  sx={{textAlign: "right", fontWeight: "bold", '&:hover': {textDecoration: "none"}}}>Tools</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row"  sx={{'&:hover': {textDecoration: "none"}}}>{row.fullname}</TableCell>
                  <TableCell sx={{'&:hover': {textDecoration: "none"}}}>{row.email}</TableCell>
                  <TableCell sx={{'&:hover': {textDecoration: "none"}}}>{row.secret}</TableCell>
                  <TableCell sx={{textAlign: "right", '&:hover': {textDecoration: "none"}}}>{row.lastLogin}</TableCell>
                  <TableCell align="right" sx={{'&:hover': {cursor: "pointer"}}}><AdminEditModal element={row} /></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>


      </Container>
    </Box>
  );
};




