import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

function footer() {
    return (
        <Box textAlign={'center'} py={4} sx={{background: '#1b1e21', color: '#8c939c'}}>
            <a href="https://weinberger-immobilien.de/impressum/" style={{color: '#8c939c', textDecoration: 'none'}} target="blank">Impressum</a> | <a href="https://weinberger-immobilien.de/datenschutz/" style={{color: '#8c939c', textDecoration: 'none'}} target="blank">Datenschutzrichtlinien</a>
        </Box>
    );
}

export default footer;