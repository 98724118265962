import * as React from 'react';
import Box from '@mui/material/Box';
import { Button } from '@mui/base/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";
import { FormControl} from '@mui/base/FormControl';
import { Input} from '@mui/base/Input';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';

import UserService from '../services/user.service';


import './forms/forms.css';
import MailerService from '../services/mailer.service';

export default function AdminEditModal(element) {

//
//  States / Form Fields
//
const currentUser = element.element

// Modal
const [open, setOpen] = React.useState(false);
const handleOpen = () => setOpen(true);
const handleClose = () => setOpen(false);

// Form
const [userId, setUserId] = React.useState(currentUser.userId);
const handleUserId = (e) => {
    const x = e.target.value;
    setUserId(x);
}

const [fullname, setFullname] = React.useState(currentUser.fullname);
const handleFullname = (e) => {
    const x = e.target.value;
    setFullname(x);
}

const [phone, setPhone] = React.useState(currentUser.phone);
const handlePhone = (e) => {
    const x = e.target.value;
    setPhone(x);
}

const [email, setEmail] = React.useState(currentUser.email);
const handleEmail = (e) => {
    const x = e.target.value;
    setEmail(x);
}

const [mobile, setMobile] = React.useState(currentUser.mobile);
const handleMobile = (e) => {
    const x = e.target.value;
    setMobile(x);
}

const [adress, setAdress] = React.useState(currentUser.adresse);
const handleAdress = (e) => {
    const x = e.target.value;
    setAdress(x);
}

const [zip, setZip] = React.useState(currentUser.zip);
const handleZip = (e) => {
    const x = e.target.value;
    setZip(x);
}

const [ort, setOrt] = React.useState(currentUser.ort);
const handleOrt = (e) => {
    const x = e.target.value;
    setOrt(x);
}

const [secret, setSecret] = React.useState(currentUser.secret);
const handleSecret = (e) => {
    const x = e.target.value;
    setSecret(x);
}

const [isFormsuccess, setIsFormSuccess] = React.useState(false);
const success = 
        <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
            Das Formular wurde erfolgreich abgeschickt. Vielen Dank!
        </Alert>;

//
//  Events
//
const navigate = useNavigate();
const handleSubmit = async (e) => {
    e.preventDefault()
    setIsFormSuccess(true);
    await UserService.ADMIN_updateUser(userId, fullname, phone, email, mobile, adress, zip, ort, secret)    
    setTimeout(() => {
        navigate('/admin')
        window.location.reload()
    }, 1500);
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: 'none',
    p: 4,
};

return (
    <div>
        <EditIcon onClick={handleOpen} sx={{float: 'right'}}/>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Container sx={style}>
                <h2 align='center' >Daten ändern</h2>
                <form autoComplete="off" onSubmit={handleSubmit}>
                    <Grid container mt={6} rowSpacing={2} columnSpacing={2} justifyContent={'center'}>
                        <Grid item xs={12} md={8}>
                            <FormControl className='wb-input-wrapper' required type="text" onChange={handleFullname} value={fullname}>
                                <label>Name</label>
                                <Input name="name" id="fullname" label="Name" variant="standard"   />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl className='wb-input-wrapper' required type="text" onChange={handleSecret} value={secret}>
                                <label>SharePoint Secret</label>
                                <Input id="secret" label="SharePoint Secret" variant="standard"  />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControl className='wb-input-wrapper' type="text" onChange={handlePhone} value={phone}>
                                <label>Telefon</label>
                                <Input id="phone" label="Telefon" variant="standard"/>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl className='wb-input-wrapper' required type="text" onChange={handleEmail} value={email}>
                                <label>E-Mail</label>
                                <Input id="email" label="E-Mail" variant="standard"  />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl className='wb-input-wrapper' type="text" onChange={handleMobile} value={mobile}>
                                <label>Mobil</label>
                                <Input id="mobile" label="Mobil" variant="standard"  />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl className='wb-input-wrapper' required type="text" onChange={handleAdress} value={adress}>
                                <label>Adresse</label>
                                <Input id="adress" label="Adresse" variant="standard"  />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl className='wb-input-wrapper' required type="text" onChange={handleZip} value={zip}>
                                <label>PLZ</label>
                                <Input id="zip" label="PLZ" variant="standard"  />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl className='wb-input-wrapper' required type="text" onChange={handleOrt} value={ort}>
                                <label>Ort</label>
                                <Input id="ort" label="Ort" variant="standard"  />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} display="flex" justifyContent="center">
                        <Box pt={5} textAlign={"center"}>
                            <Button type="submit" className='btn prim'>Speichern</Button>
                        </Box>
                    </Grid>
                </form>
                <Box mt={4} pb={4}>
                    {isFormsuccess ? success : ''}
                </Box>
            </Container>   
        </Modal>
    </div>
);
}