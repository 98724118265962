import axios from "axios";

const API_URL = process.env.REACT_APP_MAILER_API_URL

const sendPasswordReset = async () => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': JSON.parse(localStorage.getItem("user")).accessToken
    }
  }

    let response = await axios.post(API_URL + "getUserDocuments", {email: JSON.parse(localStorage.getItem("user")).email}, config)
    return response.data
};
 
const sendWarranty = async (data) => {    
  var formData = new FormData();
  for (const [key, value] of Object.entries(data)) {
    if (key === 'files' && Array.isArray(value)) {
      value.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });
    } else {
      formData.append(key, value);
    }
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'x-access-token': JSON.parse(localStorage.getItem("user")).accessToken
    }
  }

  await axios.post(API_URL + "sendWarrantyMail", formData, config)
  return;
    
};

const sendUserUpdate = async (fullname, phone, email, mobile, adress, zip, ort) => {
  let data = {
    "fullname": fullname,
    "phone": phone,
    "email": email,
    "mobile": mobile,
    "adress": adress,
    "zip": zip,
    "ort": ort
  }
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': JSON.parse(localStorage.getItem("user")).accessToken
    }
  }
  await axios.post(API_URL + "sendUserUpdateMail", data, config)
}


const MailerService = {
    sendPasswordReset,
    sendWarranty,
    sendUserUpdate
}

export default MailerService;