import axios from "axios";

const API_URL = process.env.REACT_APP_AUTH_API_URL

const register = async (fullname, phone, mobile, email, zip, ort, adress, password, secret ) => {
  let result;

  try{
    result = await axios.post(API_URL + "signup", {
      fullname,
      phone,
      mobile,
      email,
      zip,
      ort,
      adress,
      password,
      secret
    });

    return result
  } catch (e){
    return {type: "error", message: e.response.data.message}
  }
  
  
};

const login = (username, password) => {
  return axios
    .post(API_URL + "signin", {
      email: username,
      password: password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

const forgotPassword = async(username) => {
  return await axios.post(API_URL + "forgotpassword", {username});
}

const resetPassword = async(password, email, token) => {
  return await axios.post(API_URL + "resetpassword", {password, email, token});
}

const getCurrentUser = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user;
};

const isAdmin = ()  =>{
  try{
    let roles = JSON.parse(localStorage.getItem("user")).roles
    let admin = false;
  
    for(const role of roles){
      if (role == 'ROLE_ADMIN'){
        admin = true
      }
    }
    return admin
  } catch {
    return false
  }
  
}

const AuthService = {
  register,
  login,
  logout,
  forgotPassword,
  resetPassword,
  getCurrentUser,
  isAdmin
}

export default AuthService;