import React from 'react';
import { useField } from 'formik';
import { TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { FormControl} from '@mui/base/FormControl';
import { Input} from '@mui/base/Input';
import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden'

import './forms.css';

const Kontaktdaten = () => {
    const [nameField, nameMeta] = useField('name');
    const [emailField, emailMeta] = useField('email');
    const [telField, telMeta] = useField('tel');
    const [whgField, whgMeta] = useField('whg');
    const [addressField, addressMeta] = useField('address');
    const [projectField, projectMeta] = useField('project');

    return (
        <div>
            <h2>Kontaktinformationen</h2>
            <Grid container spacing={2} my={2}>
                <Grid item xs={12} md={8}>
                    <Hidden mdUp>
                        <Grid item xs={12} md={4} pb={5}>
                            <Box sx={{ backgroundColor: '#F0F2F5', p: 4 }}>
                                <Typography variant="h5" sx={{color: '#3a8787', pb: 1}}>Wichtig!</Typography>
                                <Typography variant="p">Vergessen Sie nicht, Ihre Telefonnummer und E-Mail-Adresse anzugeben. Zwingend notwendig, damit der Handwerker Sie erreichen kann!</Typography>
                            </Box>
                        </Grid>
                    </Hidden>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={10}>
                            <FormControl className='wb-input-wrapper' {...nameField} required type="text" error={nameMeta.touched && nameMeta.error ? true : false} >
                                <label>Name</label>
                                <Input name="name"  />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={10}>
                            <FormControl className='wb-input-wrapper' {...addressField} error={addressMeta.touched && addressMeta.error ? true : false} >
                                <label>Adresse</label>
                                <Input name="address" type="text"/>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <FormControl className='wb-input-wrapper' {...emailField} error={emailMeta.touched && emailMeta.error ? true : false} >
                                <label>E-Mail</label>
                                <Input name="email" type="text"/>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <FormControl className='wb-input-wrapper' {...telField} error={telMeta.touched && telMeta.error ? true : false} >
                                <label>Telefon</label>
                                <Input name="tel" type="text"/>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <FormControl className='wb-input-wrapper' {...whgField} error={whgMeta.touched && whgMeta.error ? true : false} >
                                <label>Wohnungsnummer</label>
                                <Input name="whg"  type="text"/>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <FormControl className='wb-input-wrapper' {...projectField} error={projectMeta.touched && projectMeta.error ? true : false}>
                                <label>Bauvorhaben</label>
                                <Input name="project"  type="text"/>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Hidden mdDown>
                    <Grid item xs={12} md={4}>
                        <Box sx={{ backgroundColor: '#F0F2F5', p: 4 }}>
                            <Typography variant="h5" sx={{color: '#3a8787', pb: 1}}>Wichtig!</Typography>
                            <Typography variant="p">Vergessen Sie nicht, Ihre Telefonnummer und E-Mail-Adresse anzugeben. Zwingend notwendig, damit der Handwerker Sie erreichen kann!</Typography>
                        </Box>
                    </Grid>
                </Hidden>
            </Grid>
        </div>
    );
};

export default Kontaktdaten;