import axios from "axios";

const API_URL = process.env.REACT_APP_SHAREPOINT_API_URL;

const getCommonDocuments = async () => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': JSON.parse(localStorage.getItem("user")).accessToken
    }
  }
  
  let response = await axios.post(API_URL + "getCommonDocuments", {email: JSON.parse(localStorage.getItem("user")).email}, config)
  return response.data
};

const getUserDocuments = async () => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': JSON.parse(localStorage.getItem("user")).accessToken
    }
  }

    let response = await axios.post(API_URL + "getUserDocuments", {email: JSON.parse(localStorage.getItem("user")).email}, config)
    return response.data
};

const getDownloadFile = async (reqfile, fileStatus = true) => {
  const config = {
    responseType: 'blob',
    headers: {
      'x-access-token': JSON.parse(localStorage.getItem("user")).accessToken
    }
  }

  const data = {
    email: JSON.parse(localStorage.getItem("user")).email, 
    file: reqfile,
    status: fileStatus
  }


  let response = await axios.post(API_URL + "getDownloadFile", data, config)

  return response.data
} 

const getCustomerManager = async(email) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': JSON.parse(localStorage.getItem("user")).accessToken
    }
  }

    let response = await axios.post(API_URL + "getCustomerManager", {email: email}, config)
    return response.data
}

const getReklamationen = async() => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': JSON.parse(localStorage.getItem("user")).accessToken
    }
  }

    let response = await axios.post(API_URL + "getReklamationen", {email: JSON.parse(localStorage.getItem("user")).email}, config)
    return response.data
}

const SharePointService = {
    getCommonDocuments,
    getUserDocuments,
    getDownloadFile,
    getCustomerManager,
    getReklamationen
}

export default SharePointService;