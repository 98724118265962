import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import AuthService from "./services/auth.service";

import theme from './styles/theme.js'
import Header from './components/_Header';
import Footer from './components/_Footer';
import Signup from './components/Signup';
import Login from "./components/Login";
import Admin from "./components/admin";
import Dashboard from "./components/Dashboard";
import Warranty from "./components/warranty.js";
import ForgotPassword from "./components/ForgotPassword.js";
import ResetPassword from "./components/ResetPassword.js";
import Changelog from "./components/changelog.js"

import { Box } from '@mui/system';

const PrivateRoute = ({ element }) => {
  const isLoggedIn = AuthService.getCurrentUser();

  return isLoggedIn ? (
    element
  ) : (
    <Navigate to="/login" replace />
  );
};

const AdminRoute = ({ element }) => {
  const isAdmin = AuthService.isAdmin();

  return isAdmin ? (
    element
  ) : (
    <Navigate to="/login" replace />
  );
};

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Routes>
          <Route path="/Dashboard" element={<PrivateRoute element={<Header />} />} />
          <Route path="/warranty" element={<PrivateRoute element={<Header />} />} />
        </Routes>
      </Box>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/resetPassword/:email/:token" element={<ResetPassword />} />
        <Route path="/Dashboard" element={<PrivateRoute element={<Dashboard />} />} />
        <Route path="/Warranty" element={<PrivateRoute element={<Warranty />} />} />
        <Route path="/changelog" element={<AdminRoute element={<Changelog />} />} />
        <Route path="/admin" element={<AdminRoute element={<Admin />} />} />
      </Routes>
      <Box>
        <Routes>
          <Route path="/" element={<Footer />} />
          <Route path="/signup" element={<Footer />} />
          <Route path="/login" element={<Footer />} />
          <Route path="/forgotPassword" element={<Footer />} />
          <Route path="/resetPassword" element={<Footer />} />
          <Route path="/Dashboard" element={<Footer />} />
          <Route path="/Warranty" element={<Footer />} />
          <Route path="/admin" element={<Footer />} />
        </Routes>
      </Box>
    </ThemeProvider>
  );
};

export default App;
